import { FaIcon } from "../FaIcon";
import { Row } from "../Row";
import "./Tag.scss";

export function Tag({ children }) {
	return (
		<div className="__sage-tag-container">
			<Row
				gap="1rem"
				wrap={false}
				verticalAlign="center"
				horizontalAlign="center"
			>
				<FaIcon
					icon="flame"
					color="#FFFFFF"
					padding="0"
					paddingInline="0"
					hideBg
					minWidth="0"
				/>
				{children}
			</Row>
		</div>
	);
}
