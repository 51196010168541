import { ReactNode, createContext, useContext, useMemo, useState } from "react";
import { ViewTransition } from "../ViewTransition";
import "./Carosel.scss";

interface ICaroselState {
	ids: string[];
	active: string;
}

const CaroselContext = createContext<ICaroselState>({ ids: null, active: null });

function createCaroselState({ active, ids }) {
	return {
		ids,
		active
	};
}

function useCaroselState({ id }) {
	const caroselState = useContext(CaroselContext);
	const position = useMemo(() => {
		if (caroselState.active && caroselState.ids) {
			const curIdx = caroselState.ids.indexOf(id);
			const activeIdx = caroselState.ids.indexOf(caroselState.active);
			if (curIdx == activeIdx) {
				return "active";
			} else if (curIdx > activeIdx) {
				return "right";
			} else if (curIdx < activeIdx) {
				return "left";
			}
		}
	}, [id, caroselState.active, caroselState.ids]);
	return { ...caroselState, position };
}

export function Carosel({ children, activePage }: { children: ReactNode }) {
	const state = createCaroselState({ active: activePage, ids: children.map((child) => child.props.id) });

	return (
		<CaroselContext.Provider value={state}>
			<ViewTransition width={"100%"}>
				<div className="__sage-carosel-container">{children}</div>
			</ViewTransition>
		</CaroselContext.Provider>
	);
}

export function CaroselItem({
	children,
	id,
	backgroundColor,
	backgroundImage
}: {
	children: ReactNode;
	id: string;
	backgroundColor: string;
	backgroundImage: string;
}) {
	const { position } = useCaroselState({ id });
	return (
		<div
			className={`__sage-carosel-item-container ${position}`}
			style={{ backgroundColor }} //, backgroundImage: `url("${backgroundImage}")` }}
		>
			{children}
		</div>
	);
}
